.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    min-width: 306px;
    background-color: #255b82;
    color: #FFFFFF;

}

.login-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.error-message {
    color:#fbc324;
}