.filter-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.budget-filter {
    display: flex;
    flex-wrap: wrap;
}

.filter-title {
    min-width: 100px;
    color: #2f4f4f;
}