.warehouses {
    display: flex;
    flex-direction: row;
    height: fit-content;
}

.warehouses-cart {
    max-width: 100px;
    align-self: center;
}

.warehouses .warehouse {
    width: 90%
}