.price-info,
.order-info,
.submit-order {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.price-info {
    align-items: center;
    padding-bottom: 0.2rem;
}

.order-prices {
    /*min-width: 468px;*/
}

.submit-order {
    justify-content: right;
}