.home-announcements img {
    max-width: 400px;
    max-height: 180px;
}

.home-announcements p {
    margin: 0;
    padding: 10px 2px 4px 2px;
}

.home-announcements .announcement {
    overflow-wrap: break-word;
    overflow-x: auto;
}