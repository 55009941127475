.preview-warehouses,
.preview-warehouse,
.product-preview,
.remove-product {
    display: flex;
}

.preview-warehouse {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-preview {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.preview-warehouses {
    align-items: center;
    height: 100%;
    /*min-width: 320px;*/
}

.remove-product {
    align-items: center;
}