.topFilters {
    display: flex;
    justify-content: center;
    /*background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(37, 91, 130, 1) 50%, rgba(0, 0, 0, 1) 100%);*/
    background: #FFFFFF;
    width: 100%;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
}

.topFilters-wrap {
    width: 90%;
    background: #FFFFFF;
    padding: 0.28rem;
    border: 1px solid rgba(37, 91, 130, 0.25);
    box-shadow: rgba(37, 91, 130, 0.25) 0 0.0625em 0.0625em, rgba(37, 91, 130, 0.25) 0 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0 0 0 1px inset;
}

.topFilters-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.1rem;
    flex-wrap: wrap;
}

.topFilters-inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.topFilters-buttons {
    padding: 0;
    min-width: 30px;
    max-height: 30px;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
}

.topFilters-more {
    display: flex;
    justify-content: center;
    align-items: center;
}

.topFilters-more_btn {
    min-width: 18px;
    max-height: 18px;
}

.topFilters-filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.2rem 0.2rem 0.2rem 1rem;
}

.topFilters-filter_title {
    min-width: 200px;
}

.topFilters-filter_values {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 2rem;
}