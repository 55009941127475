.video-background {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.video-background iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translate(-50%, -50%);
}