.userHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: #255b82;
    color: #FFFFFF;
    width: 100%;
    padding: 6px;
}

.userHeader-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
}