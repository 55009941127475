.order-overview {
    margin-right: 2rem;
    margin-left: 2rem;
}

.overview-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.4rem;
    background-color: #FFFFF0;
    align-items: center;
}

.overview-info {
    text-align: center;
}