.cardKpi {
    position: relative;
    padding: 1.25rem;
    margin: 1rem;
    /*box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0, rgba(255, 255, 255, 0.8) -6px -2px 16px 0;*/
    box-shadow: rgba(37, 91, 130, 0.25) 0 0.0625em 0.0625em, rgba(37, 91, 130, 0.25) 0 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0 0 0 1px inset;
    border-radius: 4px;
    min-width: 300px;
    height: auto;
}

.cardKpi img {
    max-width: 60%;
    height: auto;
}

.cardKpi__content {
    z-index: 2;
    position: relative;
}

.cardKpi__description {
    margin-bottom: 1rem;
}

.cardKpi__action {
    text-align: right;
}

.cardKpi__pre-text {
    align-self: baseline;
    font-weight: normal;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    font-size: 0.8rem;
    margin-left: 0;
}