.form_container {
    background-color: #ffffff;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
    width: 100%;
    min-width: 300px;
}

.form-flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.form-box_12 {
    width: 99.999996%;
}

.form-box_11 {
    width: 91.666663%;
}

.form-box_10 {
    width: 83.333333%;
}

.form-box_9 {
    width: 74.999997%;
}

.form-box_8 {
    width: 66.666664%;
}

.form-box_7 {
    width: 58.333331%;
}

.form-box_6 {
    width: 49.999998%;
}

.form-box_5 {
    width: 41.6666665%;
}

.form-box_4 {
    width: 33.333332%;
}

.form-box_3 {
    width: 24.999999%;
}

.form-box_2 {
    width: 16.666666%;
}

.form-box_1 {
    width: 8.333333%;
}

.form-box {
    flex: 1 0 auto;
    justify-items: inherit;
    box-sizing: border-box;
    min-width: 200px;
    align-content: flex-start;
    padding: 0.5rem 1rem;
}

.form-grid {
    display: grid;
    grid-template-columns: 58% 42%;
    grid-column-gap: 4px;
    width: 100%;
}

.form_field {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 8px;
    position: relative;
}

#pdf-content table {
    border-collapse: collapse;
    width: 100%;
    border: 2px solid #dddddd;
}


#pdf-content th {
    background-color: #dddddd;
    text-align: left;
}

#pdf-content td {
    text-align: left;
    padding: 8px;
}

.box_title {
    background-color: #dddddd;
    text-align: left;
    padding: 4px;
    font-size: 1rem;
    font-weight: 600;
}

.box_column {
    min-width: 280px;
    width: 100%;
    border: 2px solid #dddddd;
}

.box_column_item {
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding: .5rem;

}

.box_column_itemTitle {
    min-width: 135px;
    overflow-wrap: anywhere;
    word-wrap: break-word;
    color: #1a1c20;
    font-weight: 600;
}

.box_column_itemValue {
    overflow-wrap: anywhere;
}

.box_column_itemValue_invoice_total {
    overflow-wrap: anywhere;
    text-align: right;
}

.form-grid-image {
    display: grid;
    grid-template-columns: 35% 65%;
    grid-column-gap: 5px;
    width: 100%;
}

.container_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.container_2 {
    display: flex;
    flex-direction: column;
}

.titleHeaderInvoice {
    font-weight: bold;
}

.main-container {
    text-align: center;
    padding: 16px;
}

.main-title-header-invoice {
    font-size: 1rem;
    margin-top: 4px;
    text-align: center;
    font-weight: bold;
}
