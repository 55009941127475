.logos-contact-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.logos-contact-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem
}

.logo-contact-box {
    min-height: 153px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    .logos-contact-box {
        justify-content: space-between;
    }
}