.order-notes {
    min-height: 118px;
    min-width: 300px;
    max-width: 500px;
    background-color: #FFFFFF;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid rgb(238, 238, 238);
    box-shadow: rgb(250, 250, 250) 0px 2px 2px;
    color: rgb(72, 87, 118);
}