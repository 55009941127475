.sideFilters {
    width: 100%;
    background-color: #FFFFFF;
}

.sideFilters-filter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 0.5rem;
}

.sideFilters-title {
    min-width: 200px;
}